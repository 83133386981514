import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _71e8e1be = () => interopDefault(import('../src/pages/Profile/Profile.vue' /* webpackChunkName: "" */))

const _e52a422c = () => interopDefault(import('../src/pages/ProfilePosts/ProfilePosts.vue' /* webpackChunkName: "" */))
const _68f777e6 = () => interopDefault(import('../src/pages/ProfileComments/ProfileComments.vue' /* webpackChunkName: "" */))
const _29283412 = () => interopDefault(import('../src/pages/ProfileEdit/ProfileEdit.vue' /* webpackChunkName: "" */))
const _2482d178 = () => interopDefault(import('../src/pages/ProfileSettings/ProfileSettings.vue' /* webpackChunkName: "" */))
const _53a299ea = () => interopDefault(import('../src/pages/ProfileFavorites/ProfileFavorites.vue' /* webpackChunkName: "" */))
const _0903a6ac = () => interopDefault(import('../src/pages/ProfileFavoritesEdit/ProfileFavoritesEdit.vue' /* webpackChunkName: "" */))
const _492c430a = () => interopDefault(import('../src/pages/ProfileNotifications/ProfileNotifications.vue' /* webpackChunkName: "" */))
const _a16be094 = () => interopDefault(import('../src/pages/Recipes/Recipes.vue' /* webpackChunkName: "" */))
const _eaf85254 = () => interopDefault(import('../src/pages/TheRecipe.amp/TheRecipe.amp.vue' /* webpackChunkName: "" */))
const _34d3efaa = () => interopDefault(import('../src/pages/TheRecipe/TheRecipe.vue' /* webpackChunkName: "" */))
const _367bcb6c = () => interopDefault(import('../src/pages/ArticleNavigator/ArticleNavigator.vue' /* webpackChunkName: "" */))
const _183da74e = () => interopDefault(import('../src/pages/ArchivePage/ArchivePage.vue' /* webpackChunkName: "" */))
const _131b7e82 = () => interopDefault(import('../src/pages/SingleArticle.amp.vue' /* webpackChunkName: "" */))
const _2d00c910 = () => interopDefault(import('../src/pages/Yandbtm.vue' /* webpackChunkName: "" */))
const _4c3c07a0 = () => interopDefault(import('../src/pages/SearchArchive/SearchArchive.vue' /* webpackChunkName: "" */))
const _1c4215ec = () => interopDefault(import('../src/pages/HealthHome/HealthHome.vue' /* webpackChunkName: "" */))
const _af0acbac = () => interopDefault(import('../src/pages/HealthServices/HealthServices.vue' /* webpackChunkName: "" */))
const _014c91bc = () => interopDefault(import('../src/pages/HealthNavigator/HealthNavigator.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/profile/user/:userId",
    component: _71e8e1be,
    meta: {},
    alias: [],
    children: [{
      path: "",
      redirect: "comments"
    }, {
      path: "posts",
      component: _e52a422c,
      name: "profile-posts"
    }, {
      path: "comments",
      component: _68f777e6,
      name: "profile-comments"
    }, {
      path: "edit",
      component: _29283412,
      meta: {"requiresAuth":true},
      name: "profile-edit"
    }, {
      path: "settings",
      component: _2482d178,
      meta: {"requiresAuth":true},
      name: "profile-settings"
    }, {
      path: "favorites",
      component: _53a299ea,
      meta: {"requiresAuth":true},
      name: "profile-favorites"
    }, {
      path: "favorites-edit",
      component: _0903a6ac,
      meta: {"requiresAuth":true},
      name: "profile-favorites-edit"
    }, {
      path: "notifications",
      component: _492c430a,
      meta: {"requiresAuth":true},
      name: "profile-notifications"
    }]
  }, {
    path: "/recipes/",
    component: _a16be094,
    meta: {},
    alias: [],
    name: "recipes-main-page"
  }, {
    path: "/recipes/:slug*/page/:activePage",
    component: _a16be094,
    meta: {},
    props: true,
    alias: [],
    name: "recipes-with-page"
  }, {
    path: "/recipes/:slug*",
    component: _a16be094,
    meta: {},
    props: true,
    alias: [],
    name: "recipes"
  }, {
    path: "/recipe/:slug/amp",
    component: _eaf85254,
    meta: {},
    props: true,
    alias: [],
    name: "TheRecipeAmp"
  }, {
    path: "/recipe/:slug",
    component: _34d3efaa,
    meta: {},
    props: true,
    alias: [],
    name: "TheRecipe"
  }, {
    path: "/preview/recipe/:id",
    component: _34d3efaa,
    meta: {},
    props: (route) => ({
    isPreview: true,
    id: +route.params.id
  }),
    alias: [],
    name: "recipe-preview"
  }, {
    path: "/topics/",
    component: _367bcb6c,
    meta: {},
    alias: [],
    name: "single-topics"
  }, {
    path: "/topics/:pathMatch(.*)",
    component: _183da74e,
    meta: {},
    alias: [],
    name: "topics-all"
  }, {
    path: "/author/:pathMatch(.*)",
    component: _183da74e,
    meta: {},
    alias: ["/health/author/:pathMatch(.*)"],
    name: "author-all"
  }, {
    path: "/tag/:pathMatch(.*)",
    component: _183da74e,
    meta: {},
    alias: ["/health/tag/:pathMatch(.*)"],
    name: "tag-all"
  }, {
    path: "/list/",
    component: _183da74e,
    meta: {},
    alias: ["/health/list/"],
    name: "list-all"
  }, {
    path: "/stream/:pathMatch(.*)",
    component: _183da74e,
    meta: {},
    alias: ["/health/stream/:pathMatch(.*)"],
    name: "stream-all"
  }, {
    path: "/top/:pathMatch(week.*|month.*|year.*|all.*)?",
    component: _183da74e,
    meta: {},
    alias: ["/health/top/:pathMatch(week.*|month.*|year.*|all.*)?"],
    name: "top-all"
  }, {
    path: "/",
    component: _183da74e,
    meta: {},
    alias: [],
    name: "index"
  }, {
    path: "/:year([0-9]{4})",
    component: _183da74e,
    meta: {},
    alias: ["/health/:year([0-9]{4})"],
    name: "archive-year"
  }, {
    path: "/:year([0-9]{4})/:month([0-9]{1,2})",
    component: _183da74e,
    meta: {},
    alias: ["/health/:year([0-9]{4})/:month([0-9]{1,2})"],
    name: "archive-month"
  }, {
    path: "/:year([0-9]{4})/:month([0-9]{1,2})/:day([0-9]{1,2})",
    component: _183da74e,
    meta: {},
    alias: ["/health/:year([0-9]{4})/:month([0-9]{1,2})/:day([0-9]{1,2})"],
    name: "archive-day"
  }, {
    path: "/preview/:articleId",
    component: _367bcb6c,
    meta: {},
    alias: [],
    name: "preview"
  }, {
    path: "/:slug*/amp",
    component: _131b7e82,
    meta: {},
    alias: [],
    name: "single-amp"
  }, {
    path: "/yandbtm",
    component: _2d00c910,
    meta: {},
    alias: [],
    name: "yandbtm"
  }, {
    path: "/search",
    component: _4c3c07a0,
    meta: {},
    alias: [],
    name: "search"
  }, {
    path: "/ext-search/",
    redirect: "search",
    meta: {},
    alias: [],
    name: "ext-search"
  }, {
    path: "/health/",
    component: _1c4215ec,
    meta: {},
    alias: [],
    name: "health-home"
  }, {
    path: "/health/services",
    component: _af0acbac,
    meta: {},
    alias: [],
    name: "health-services"
  }, {
    path: "/health/:category/:slug",
    component: _014c91bc,
    meta: {},
    alias: [],
    name: "health-article"
  }, {
    path: "/health/:categoryOrSlug",
    component: _014c91bc,
    meta: {},
    alias: [],
    name: "health-section"
  }, {
    path: "/*",
    component: _367bcb6c,
    meta: {},
    alias: [],
    name: "single"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
